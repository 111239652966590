export function useGetTitleByLevel() {
  const { t } = useI18n()

  function getTitle(title: string, level?: number) {
    if (!level) return title

    switch (level) {
      case 1:
        return t('bonuses.depositNTitle', {
          type: title,
          level: t('bonuses.levels.first'),
        })
      case 2:
        return t('bonuses.depositNTitle', {
          type: title,
          level: t('bonuses.levels.second'),
        })
      case 3:
        return t('bonuses.depositNTitle', {
          type: title,
          level: t('bonuses.levels.third'),
        })
      default:
        return t('bonuses.depositNTitle', { type: title }, level)
    }
  }

  return {
    getTitle,
  }
}
